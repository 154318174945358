/* eslint-disable no-nested-ternary */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../../components/layout'
import RichText from '../../components/RichText'
import Hero from '../../components/Hero'
import ContentWrapper from '../../components/ContentWrapper'
import DetailContainer from '../../components/DetailContainer'
import DetailContent from '../../components/DetailContent'
import { H2, A, SideNavHeading, P } from '../../components/Typography'
import SideNavList from '../../components/SideNavList'
import SideNavContainer from '../../components/SideNavContainer'
import SideNavListItem from '../../components/SideNavListItem'
import InternalLink from '../../components/InternalLink'
import SideNav from '../../components/SideNav'
import Anchor from '../../components/Anchor'
import { BREAKPOINT, SPACING } from '../../constants'

const Contribution = styled.div`
  margin-bottom: ${SPACING.XXL};
  margin-left: ${SPACING.XXL};
  margin-top: 20px;

  @media (min-width: ${BREAKPOINT.L}) {
    margin-left: 0px;
    margin-top: 0px;
  }
`

const ExampleImageWrapper = styled.div`
  margin-top: ${SPACING.XL};
  margin-bottom: ${SPACING.XXL};
`
const StyledImage = styled(Img)``

const exampleTemplate = ({ data: { example, exampleIcon } }) => (
  <Layout title={example.name} seoDescription={example.seoDescription}>
    <Hero
      type="example"
      heading={example.name}
      tags={example.associatedPatterns}
      heroImage={exampleIcon.icon && exampleIcon.icon}
    />

    <ContentWrapper>
      <DetailContainer>
        <DetailContent>
          <section>
            <H2>
              <Anchor id="about" />
              About
            </H2>
            {RichText(example.aboutText)}

            {example.aboutImage && (
              <ExampleImageWrapper>
                <StyledImage
                  fluid={example.aboutImage.fluid}
                  alt={example.aboutImage.description}
                  key={example.aboutImage.fluid.src}
                />
              </ExampleImageWrapper>
            )}
          </section>

          {example.keyGuidance && (
            <section>
              <H2>
                <Anchor id="key-guidance" />
                Key guidance
              </H2>
              {RichText(example.keyGuidance)}
            </section>
          )}

          {example.risks && (
            <section>
              <H2>
                <Anchor id="risks" />
                Risks
              </H2>
              {RichText(example.risks)}
              {console.log(example.risks)}
            </section>
          )}

          <section>
            <H2>
              <Anchor id="where-can-i-find-this" />
              Where can I find this?
            </H2>
            <A href={example.exampleLink}>{example.exampleLinkName}</A>
          </section>

          {example.contact && (
            <section>
              <H2>
                <Anchor id="contact" />
                Contact
              </H2>
              <P>
                {example.contact.name}
                <br />
                <br />
                <A href={`mailto:${example.contact.emailAddress}`}>
                  {example.contact.emailAddress}
                </A>
                <br />
              </P>
            </section>
          )}
        </DetailContent>
        <SideNav>
          <SideNavContainer>
            {example.contributingOrganisation && (
              <Contribution>
                <SideNavHeading>Organisation</SideNavHeading>
                <A href={example.contributingOrganisation.link}>
                  {(() => {
                    // Check if logo is an svg
                    if (example.contributingOrganisation.logo)
                      return (
                        <img
                          src={example.contributingOrganisation.logo.file.url}
                          alt={`${example.contributingOrganisation.name} logo`}
                        />
                      )
                    // If there is a logo image file (non-svg)
                    // else return organisation name
                    if (
                      example.contributingOrganisation.logo &&
                      example.contributingOrganisation.logo.file.contentType !==
                        'image/svg'
                    )
                      return (
                        <StyledImage
                          fluid={example.contributingOrganisation.logo.fluid}
                          alt={`${example.contributingOrganisation.name} logo`}
                          key={example.contributingOrganisation.logo.fluid.src}
                        />
                      )
                    return `${example.contributingOrganisation.name}`
                  })()}
                </A>
              </Contribution>
            )}
            <SideNavList>
              <SideNavHeading>In this example</SideNavHeading>
              {example.aboutText && (
                <SideNavListItem>
                  <InternalLink to="#about">About</InternalLink>
                </SideNavListItem>
              )}

              {example.keyGuidance && (
                <SideNavListItem>
                  <InternalLink to="#key-guidance">Key guidance</InternalLink>
                </SideNavListItem>
              )}

              {example.risks && (
                <SideNavListItem>
                  <InternalLink to="#risks">Risks</InternalLink>
                </SideNavListItem>
              )}

              {example.exampleLink && (
                <SideNavListItem>
                  <InternalLink to="#where-can-i-find-this">
                    Where can I find this
                  </InternalLink>
                </SideNavListItem>
              )}

              {example.contact && (
                <SideNavListItem>
                  <InternalLink to="#contact">Contact</InternalLink>
                </SideNavListItem>
              )}
            </SideNavList>
          </SideNavContainer>
        </SideNav>
      </DetailContainer>
    </ContentWrapper>
  </Layout>
)
export const query = graphql`
  query($slug: String) {
    example: contentfulExample(slug: { eq: $slug }) {
      name
      seoDescription
      associatedPatterns {
        name
        slug
      }
      contributingOrganisation {
        name
        link
        logo {
          description
          fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid
          }
          file {
            contentType
            url
          }
        }
      }
      aboutText {
        json
      }
      aboutImage {
        fluid {
          ...GatsbyContentfulFluid
        }
        description
      }
      exampleLinkName
      exampleLink
      quote
      quoteAuthor
      keyGuidance {
        json
      }
      risks {
        json
      }
      contact {
        ... on ContentfulContact {
          name
          emailAddress
        }
      }
    }
    exampleIcon: contentfulContentTypeInfo(contentType: { eq: "example" }) {
      contentType
      icon {
        file {
          url
        }
      }
    }
  }
`

export default exampleTemplate
